import { Container } from 'components';
import { Link } from 'gatsby';
import { heroContentStyles } from 'modules/home';
import React from 'react';
import { buttons } from 'styles';

interface Props {
  title: string;
  description: string;
  slide: number;
  to?: string;
  buttonText?: string;
}

export const HeroSectionContent: React.FC<Props> = ({
  title,
  description,
  buttonText,
  to,
}) => {
  return (
    <Container>
      <div css={heroContentStyles.root}>
        <h1 css={heroContentStyles.title}>{title}</h1>
        <p css={heroContentStyles.description}>{description}</p>
        {to && (
          <Link to={to} css={buttons.primary.medium}>
            {buttonText}
          </Link>
        )}
      </div>
    </Container>
  );
};
