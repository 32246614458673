import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { HomeArticle } from 'modules/home';
import React from 'react';

import { ProductSection } from './ProductSection';

export const TondachRoof: React.FC = () => {
  const roofPhoto = graphql`
    query {
      roof: file(relativePath: { eq: "homepage-roof.png" }) {
        id
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      roof1: allCombinedProductsJson(
        filter: {
          commercial: {
            navigationCategories: { code: { eq: "Roof" } }
            name: { eq: "Contiton 9 Natur Color antracit" }
          }
        }
      ) {
        nodes {
          commercial {
            assets {
              otherImgs {
                imgUrl
                assetType
              }
              mainImg
            }
            model
            category
            name
            localizedName
            navigationCategories {
              code
            }
          }
          id
        }
      }
      roof2: allCombinedProductsJson(
        filter: {
          commercial: {
            navigationCategories: { code: { eq: "Roof" } }
            name: { eq: "Veneton 11 Natur Color bijela" }
          }
        }
      ) {
        nodes {
          commercial {
            assets {
              otherImgs {
                imgUrl
                assetType
              }
              mainImg
            }
            model
            category
            name
            localizedName
            navigationCategories {
              code
            }
          }
          id
        }
      }
      roof3: allCombinedProductsJson(
        filter: {
          commercial: {
            navigationCategories: { code: { eq: "Roof" } }
            name: { eq: "Traditon 9 Natur Color granit" }
          }
        }
      ) {
        nodes {
          commercial {
            assets {
              otherImgs {
                imgUrl
                assetType
              }
              mainImg
            }
            model
            category
            name
            localizedName
            navigationCategories {
              code
            }
          }
          id
        }
      }
    }
  `;

  const { formatMessage } = useIntl();
  const roof1 = useStaticQuery(roofPhoto).roof1.nodes[0];
  const roof2 = useStaticQuery(roofPhoto).roof2.nodes[0];
  const roof3 = useStaticQuery(roofPhoto).roof3.nodes[0];

  const products: HomeArticle[] = [
    {
      image:
        roof1.commercial.assets.mainImg ||
        roof1.commercial.assets.otherImgs[0].imgUrl,
      title: roof1.commercial.localizedName,
      type: roof1.commercial.category,
      imageAlt: roof1.commercial.localizedName,
      id: roof1.id,
      commercial: roof1.commercial,
    },
    {
      image:
        roof2.commercial.assets.mainImg ||
        roof2.commercial.assets.otherImgs[0].imgUrl,
      title: roof2.commercial.localizedName,
      type: roof2.commercial.category,
      imageAlt: roof2.commercial.localizedName,
      id: roof2.id,
      commercial: roof2.commercial,
    },
    {
      image:
        roof3.commercial.assets.mainImg ||
        roof3.commercial.assets.otherImgs[0].imgUrl,
      title: roof3.commercial.localizedName,
      type: roof3.commercial.category,
      imageAlt: roof3.commercial.localizedName,
      id: roof3.id,
      commercial: roof3.commercial,
    },
  ];

  return (
    <ProductSection
      title={formatMessage({ id: 'home.tondach-roof.title' })}
      description={formatMessage({ id: 'home.tondach-roof.description' })}
      image={useStaticQuery(roofPhoto).roof}
      imageAlt={formatMessage({ id: 'home.tondach-roof.imageAlt' })}
      imagePosition="right"
      products={products}
      allProducts="/krov/pregled-svih-proizvoda"
    />
  );
};
