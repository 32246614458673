import 'pure-react-carousel/dist/react-carousel.es.css';

import { Copy } from 'components';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { useIntl } from 'gatsby-plugin-intl';
import { heroStyles } from 'modules/home';
import { CarouselProvider, Dot, Slide, Slider } from 'pure-react-carousel';
import React, { useState } from 'react';

import { HeroSectionContent } from './HeroSectionContent';

export const HeroSection: React.FC = () => {
  const { formatMessage } = useIntl();
  const [autoPlay, setAutoPlay] = useState<boolean>(true);

  const heroPhoto = graphql`
    query {
      hero1: file(relativePath: { eq: "home-backgrounds/homepage-hero.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 2279, quality: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero2: file(relativePath: { eq: "home-backgrounds/homepage-hero2.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 2279, quality: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `;

  const onMouseEnter = () => {
    if (autoPlay) setAutoPlay(false);
  };

  const onMouseLeave = () => {
    if (!autoPlay) setAutoPlay(true);
  };

  return (
    <div css={heroStyles.root}>
      <CarouselProvider
        visibleSlides={1}
        naturalSlideWidth={100}
        naturalSlideHeight={400}
        totalSlides={2}
        isPlaying={autoPlay}
        interval={4000}
        isIntrinsicHeight
      >
        <Slider
          trayProps={{ onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave }}
        >
          <Slide index={0}>
            <BackgroundImage
              css={heroStyles.image}
              {...useStaticQuery(heroPhoto).hero1.childImageSharp}
            >
              <HeroSectionContent
                title={formatMessage({ id: 'home.hero-section.title.1' })}
                description={formatMessage({
                  id: 'home.hero-section.description.1',
                })}
                slide={0}
              />
            </BackgroundImage>
          </Slide>
          <Slide index={1}>
            <BackgroundImage
              css={heroStyles.image}
              {...useStaticQuery(heroPhoto).hero2.childImageSharp}
            >
              <HeroSectionContent
                title={formatMessage({ id: 'home.hero-section.title.2' })}
                description={formatMessage({
                  id: 'home.hero-section.description.2',
                })}
                slide={1}
              />
            </BackgroundImage>
          </Slide>
        </Slider>
        <div css={heroStyles.container}>
          <div css={heroStyles.infoWrapper}>
            <div css={heroStyles.dotWrapper}>
              <Dot slide={0} css={heroStyles.navigationDot}></Dot>
              <Dot slide={1} css={heroStyles.navigationDot}></Dot>
            </div>
            <Copy
              title={formatMessage({ id: 'home.hero-section.copy.title' })}
              step1Title={formatMessage({
                id: 'home.hero-section.copy.step1Title',
              })}
              step1Description={formatMessage({
                id: 'home.hero-section.copy.step1Description',
              })}
              step2Title={formatMessage({
                id: 'home.hero-section.copy.step2Title',
              })}
              step2Description={formatMessage({
                id: 'home.hero-section.copy.step2Description',
              })}
              step3Title={formatMessage({
                id: 'home.hero-section.copy.step3Title',
              })}
              step3Description={formatMessage({
                id: 'home.hero-section.copy.step3Description',
              })}
            />
          </div>
        </div>
      </CarouselProvider>
    </div>
  );
};
