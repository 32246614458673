import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { HomeArticle } from 'modules/home';
import React from 'react';

import { ProductSection } from './ProductSection';

export const ProthermWallSystems: React.FC = () => {
  const wallPhoto = graphql`
    query {
      walls: file(relativePath: { eq: "homepage-walls.png" }) {
        id
        childImageSharp {
          fluid(quality: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      porotherm_1: allCombinedProductsJson(
        filter: {
          commercial: {
            navigationCategories: { code: { eq: "Wall" } }
            name: { eq: "Porotherm 38 IZO Profi" }
          }
        }
      ) {
        nodes {
          commercial {
            assets {
              otherImgs {
                imgUrl
                assetType
              }
              mainImg
            }
            model
            category
            name
            localizedName
            navigationCategories {
              code
            }
          }
          id
        }
      }
      porotherm_2: allCombinedProductsJson(
        filter: {
          commercial: {
            navigationCategories: { code: { eq: "Wall" } }
            name: { eq: "Porotherm 30 Profi" }
          }
        }
      ) {
        nodes {
          commercial {
            assets {
              otherImgs {
                imgUrl
                assetType
              }
              mainImg
            }
            model
            category
            name
            localizedName
            navigationCategories {
              code
            }
          }
          id
        }
      }
      porotherm_3: allCombinedProductsJson(
        filter: {
          commercial: {
            navigationCategories: { code: { eq: "WallAcc" } }
            name: { eq: "Porotherm Profi kut 300x249" }
          }
        }
      ) {
        nodes {
          commercial {
            assets {
              otherImgs {
                imgUrl
                assetType
              }
              mainImg
            }
            model
            category
            name
            localizedName
            navigationCategories {
              code
            }
          }
          id
        }
      }
    }
  `;

  const { formatMessage } = useIntl();
  const porotherm1 = useStaticQuery(wallPhoto).porotherm_1.nodes[0];
  const porotherm2 = useStaticQuery(wallPhoto).porotherm_2.nodes[0];
  const porotherm3 = useStaticQuery(wallPhoto).porotherm_3.nodes[0];

  const products: HomeArticle[] = [
    {
      image:
        porotherm1.commercial.assets.mainImg ||
        porotherm1.commercial.assets.otherImgs[0].imgUrl,
      title: porotherm1.commercial.localizedName,
      type: porotherm1.commercial.category,
      imageAlt: porotherm1.commercial.localizedName,
      id: porotherm1.id,
      commercial: porotherm1.commercial,
    },
    {
      image:
        porotherm2.commercial.assets.mainImg ||
        porotherm2.commercial.assets.otherImgs[0].imgUrl,
      title: porotherm2.commercial.localizedName,
      type: porotherm2.commercial.category,
      imageAlt: porotherm2.commercial.localizedName,
      id: porotherm2.id,
      commercial: porotherm2.commercial,
    },
    {
      image:
        porotherm3.commercial.assets.mainImg ||
        porotherm3.commercial.assets.otherImgs[0].imgUrl,
      title: porotherm3.commercial.localizedName,
      type: porotherm3.commercial.category,
      imageAlt: porotherm3.commercial.localizedName,
      id: porotherm3.id,
      commercial: porotherm3.commercial,
    },
  ];

  return (
    <ProductSection
      title={formatMessage({ id: 'home.porotherm-wall-systems.title' })}
      description={formatMessage({
        id: 'home.porotherm-wall-systems.description',
      })}
      image={useStaticQuery(wallPhoto).walls}
      imageAlt={formatMessage({ id: 'home.porotherm-wall-systems.imageAlt' })}
      imagePosition="left"
      products={products}
      allProducts="/zid/pregled-svih-proizvoda"
    />
  );
};
