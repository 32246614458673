import { css } from '@emotion/core';
import {
  colors,
  fontFamily,
  fontWeights,
  mqFrom,
  typescale,
  unit,
} from 'styles';

const root = css`
  background-color: ${colors.backgroundGray};
`;

const container = css`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;
  width: 100%;

  ${mqFrom.Med} {
    flex-direction: row;
  }
`;

const titleWrapper = css`
  padding: ${unit * 5}px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${mqFrom.Med} {
    max-width: 30%;
    padding: 0;
    align-items: flex-start;
  }
`;

const title = css`
  ${typescale.xlarge};
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;
  color: ${colors.dark};
  padding-bottom: ${unit * 7}px;
`;

const imagesWrapper = css`
  width: 100vw;
  padding: ${unit * 3}px 0;

  ${mqFrom.Sml} {
    width: 85vw;
    padding: 0;
  }

  ${mqFrom.Med} {
    width: 40vw;
  }

  ${mqFrom.Xlrg} {
    width: 35vw;
  }

  ${mqFrom.Xxlrg} {
    width: 30vw;
  }
`;

const backgroundImage = css`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: ${unit * 2}px 0;

  ${mqFrom.Med} {
    padding: ${unit * 4}px 0;
  }

  ${mqFrom.Xlrg} {
    padding: ${unit * 7}px 0;
  }
`;

const logo = css`
  width: 130px;
  height: 130px;

  ${mqFrom.Med} {
    width: 180px;
    height: 180px;
  }

  ${mqFrom.Xlrg} {
    width: 220px;
    height: 220px;
  }
`;

export const warrantyStyles = {
  root,
  container,
  title,
  titleWrapper,
  imagesWrapper,
  backgroundImage,
  logo,
};
