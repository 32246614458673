import { css } from '@emotion/core';
import {
  colors,
  fontFamily,
  fontWeights,
  layout,
  mqFrom,
  typescale,
  unit,
} from 'styles';

const root = css`
  background-color: ${colors.backgroundGray};
`;

const container = css`
  position: relative;
  ${layout.wrapper};
`;

const reviewWrapper = css`
  display: flex;
  flex-direction: column;

  transition: all 0.5s;

  padding: ${unit * 3}px ${unit}px;

  ${mqFrom.Sml} {
    padding: ${unit * 5}px ${unit * 2}px;
  }

  ${mqFrom.Xlrg} {
    flex-direction: row;
    padding: ${unit * 10}px ${unit * 11}px;
  }
`;

const review = css`
  transition: all 0.5s;
  padding: ${unit * 2}px ${unit}px;

  ${mqFrom.Sml} {
    padding: ${unit * 3}px ${unit * 3}px;
  }

  ${mqFrom.Med} {
    padding: ${unit * 3}px ${unit * 6}px;
  }

  ${mqFrom.Xlrg} {
    padding: 0 ${unit * 11}px;
    width: 33%;
  }
`;

const photo = css`
  width: 48px;
  height: 48px;

  min-width: 48px;

  border-radius: 50%;
`;

const authorWrapper = css`
  display: flex;
  flex-direction: row;
  padding-top: ${unit * 6}px;
`;

const nameWrapper = css`
  margin-left: ${unit * 3}px;
`;

const text = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};
  ${typescale.base};

  color: ${colors.textSecondary};
`;

const name = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightBold};
  ${typescale.base};

  color: ${colors.textSecondary};
`;

const role = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};
  ${typescale.base};

  color: ${colors.textSecondary};
  opacity: 0.8;
`;

const title = css`
  padding-top: ${unit * 15}px;
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};
  ${typescale.tiny};

  color: ${colors.dark};
  text-transform: uppercase;
`;

const navigationButtonWrapper = css`
  position: absolute;
  right: 50px;
`;

const dot = css`
  background-color: ${colors.dark};
  opacity: 0.2;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  padding: 0;
  border: 0;
  margin: 0 ${unit}px;

  &:disabled {
    background-color: ${colors.primary};
    opacity: 1;
  }

  &:active {
    background-color: ${colors.primary};
    opacity: 1;
  }
`;

const dotWrapper = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: ${unit * 6}px 0;
`;

const navigationButton = css`
  outline: none;
  background-color: ${colors.backgroundGray};
  border: 0;
`;

export const reviewStyles = {
  root,
  container,
  reviewWrapper,
  review,
  text,
  name,
  role,
  title,
  photo,
  dot,
  dotWrapper,
  authorWrapper,
  navigationButtonWrapper,
  navigationButton,
  nameWrapper,
};
