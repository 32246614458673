import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import { HomeArticle } from '../models';
import { ProductSection } from './ProductSection';

export const TondachRoofSystems: React.FC = () => {
  const roofWindowPhoto = graphql`
    query {
      roofWindow: file(relativePath: { eq: "homepage-windows.jpg" }) {
        id
        childImageSharp {
          fluid(quality: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      energyPlus: allCombinedProductsJson(
        filter: {
          commercial: {
            navigationCategories: { code: { eq: "RoofWindows" } }
            name: { eq: "EnergyPlus roof window 55x78 cm" }
          }
        }
      ) {
        nodes {
          commercial {
            assets {
              otherImgs {
                imgUrl
                assetType
              }
              mainImg
            }
            model
            category
            name
            localizedName
            navigationCategories {
              code
            }
          }
          id
        }
      }
      project: allCombinedProductsJson(
        filter: {
          commercial: {
            navigationCategories: { code: { eq: "RoofWindows" } }
            name: { eq: "Project roof window 66x118 cm" }
          }
        }
      ) {
        nodes {
          commercial {
            assets {
              otherImgs {
                imgUrl
                assetType
              }
              mainImg
            }
            model
            category
            name
            localizedName
            navigationCategories {
              code
            }
          }
          id
        }
      }
      smartPro: allCombinedProductsJson(
        filter: {
          commercial: {
            navigationCategories: { code: { eq: "RoofWindows" } }
            name: { eq: "SmartPro roof window 66x118 cm" }
          }
        }
      ) {
        nodes {
          commercial {
            assets {
              otherImgs {
                imgUrl
                assetType
              }
              mainImg
            }
            model
            category
            name
            localizedName
            navigationCategories {
              code
            }
          }
          id
        }
      }
    }
  `;

  const { formatMessage } = useIntl();
  const energyPlus = useStaticQuery(roofWindowPhoto).energyPlus.nodes[0];
  const project = useStaticQuery(roofWindowPhoto).project.nodes[0];
  const smartPro = useStaticQuery(roofWindowPhoto).smartPro.nodes[0];

  const products: HomeArticle[] = [
    {
      image:
        energyPlus.commercial.assets.mainImg ||
        energyPlus.commercial.assets.otherImgs[0].imgUrl,
      title: energyPlus.commercial.localizedName,
      type: energyPlus.commercial.model,
      imageAlt: energyPlus.commercial.localizedName,
      id: energyPlus.id,
      commercial: energyPlus.commercial,
    },
    {
      image:
        project.commercial.assets.mainImg ||
        project.commercial.assets.otherImgs[0].imgUrl,
      title: project.commercial.localizedName,
      type: project.commercial.model,
      imageAlt: project.commercial.localizedName,
      id: project.id,
      commercial: project.commercial,
    },
    {
      image:
        smartPro.commercial.assets.mainImg ||
        smartPro.commercial.assets.otherImgs[0].imgUrl,
      title: smartPro.commercial.localizedName,
      type: smartPro.commercial.model,
      imageAlt: smartPro.commercial.localizedName,
      id: smartPro.id,
      commercial: smartPro.commercial,
    },
  ];

  return (
    <ProductSection
      title={formatMessage({ id: 'home.tondach-roof-systems.title' })}
      description={formatMessage({
        id: 'home.tondach-roof-systems.description',
      })}
      image={useStaticQuery(roofWindowPhoto).roofWindow}
      imageAlt={formatMessage({ id: 'home.tondach-roof-systems.imageAlt' })}
      imagePosition="left"
      products={products}
      allProducts="/krovni-prozori/pregled-svih-proizvoda"
    />
  );
};
