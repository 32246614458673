import { css } from '@emotion/core';
import {
  buttons,
  colors,
  fontFamily,
  fontWeights,
  mqFrom,
  typescale,
  unit,
} from 'styles';

const root = css`
  background-color: ${colors.backgroundGray};
`;

const container = css`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  width: 100%;

  padding: ${unit * 10}px 0;

  ${mqFrom.Sml} {
    padding: ${unit * 15}px 0;
  }

  ${mqFrom.Med} {
    padding: ${unit * 18}px 0;
  }

  ${mqFrom.Lrg} {
    padding: ${unit * 23}px 0;
  }
`;

const title = css`
  ${typescale.medium};
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;
  color: ${colors.dark};
  padding-bottom: ${unit * 12}px;
  transition: all 0.5s;

  ${mqFrom.Med} {
    ${typescale.large};
  }

  ${mqFrom.Lrg} {
    ${typescale.xlarge};
  }
`;

const description = css`
  ${typescale.base};
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;

  color: ${colors.textSecondary};
  opacity: 0.8;

  padding: 0 ${unit * 6}px;
`;

const descriptionWrapper = css`
  display: flex;
  flex-direction: row;
  padding: ${unit * 4}px 0;

  ${mqFrom.Sml} {
    padding: 0;
  }
`;

const content = css`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  ${mqFrom.Sml} {
    flex-direction: row;
  }
`;

const iconWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

const button = css`
  ${buttons.ghost.small};
  margin-top: ${unit * 8}px;
  margin-left: 74px;
  margin-bottom: ${unit * 3}px;

  ${mqFrom.Med} {
    margin-bottom: 0;
  }
`;
export const calculatorStyles = {
  root,
  container,
  title,
  description,
  descriptionWrapper,
  content,
  iconWrapper,
  button,
};
