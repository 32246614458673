import { css } from '@emotion/core';
import {
  buttons,
  color,
  colors,
  fontFamily,
  fontWeights,
  mqFrom,
  typescale,
  unit,
} from 'styles';

const root = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${unit * 15}px 0;
`;

const photo = css`
  transition: all 0.5s;

  ${mqFrom.Med} {
    width: 50%;
    height: 420px;
    position: relative;
  }

  &:after {
    content: ' ';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    background: white;
    transition: transform 0.5s;
    transform-origin: bottom left;
    transform: skew(-9deg);
  }

  ${mqFrom.Xlrg} {
    &:after {
      transform: skew(-15deg);
    }
  }
`;

const photoLeft = css`
  ${photo};

  &:after {
    left: 80%;
  }
`;

const photoRight = css`
  ${photo};

  &:after {
    right: 95%;
  }
`;

const productPhoto = css`
  width: 100px;
  height: 100px;
  margin-top: ${unit * 10}px;

  transition: all 0.5s;

  ${mqFrom.Med} {
    margin-top: 0;
  }

  ${mqFrom.Xlrg} {
    width: 140px;
    height: 140px;
  }
`;

const title = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightBold};
  ${typescale.large};

  color: ${colors.dark};

  max-width: 60%;
`;

const wrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  ${mqFrom.Med} {
    max-width: 45%;
  }
`;

const description = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  ${typescale.small};

  color: ${color(colors.textSecondary, 0.7)};

  padding: ${unit * 3}px 0;
  max-width: 60%;
`;

const product = css`
  display: flex;
  flex-direction: column;
  max-width: 33%;
  padding: 0 ${unit * 2}px;
`;

const productWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const productType = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;
  ${typescale.tiny};

  color: ${color(colors.textSecondary, 0.6)};
  text-transform: uppercase;
  padding-top: ${unit * 6}px;
`;

const productTitle = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;
  ${typescale.small};

  color: ${colors.textSecondary};
  padding: ${unit}px 0;
`;

const titleWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const button = css`
  ${buttons.buttonBase};
  text-decoration: underline;

  transition: all 0.3s ease-out;

  &:hover {
    color: ${colors.textSecondary};
  }
`;

export const productStyles = {
  root,
  photoLeft,
  photoRight,
  title,
  button,
  description,
  productPhoto,
  product,
  productWrapper,
  productType,
  productTitle,
  wrapper,
  titleWrapper,
};
