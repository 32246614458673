import { SEO } from 'components/SEO';
import { PageProps } from 'gatsby';
import {
  CalculatorSection,
  HeroSection,
  ProthermWallSystems,
  ReviewSection,
  TercaFacadeBrick,
  TondachRoof,
  TondachRoofSystems,
  WarrantySection,
} from 'modules/home';
import React from 'react';

import { Container } from '../components';

const IndexPage: React.FC<PageProps> = () => {
  return (
    <>
      <SEO />
      <HeroSection />
      <Container>
        <ProthermWallSystems />
      </Container>
      <CalculatorSection />
      <Container>
        <TondachRoof />
      </Container>
      <Container>
        <TondachRoofSystems />
      </Container>
      <WarrantySection />
      <Container>
        <TercaFacadeBrick />
      </Container>
      <ReviewSection />
    </>
  );
};

export default IndexPage;
