import { css } from '@emotion/core';
import { mqFrom } from 'styles';

import { calculatorStyles } from './calculatorStyles';

const root = css`
  ${calculatorStyles.root};
`;

const title = css`
  ${calculatorStyles.title};
`;

const container = css`
  ${calculatorStyles.container};
`;

const content = css`
  display: flex;
  flex-direction: column;
`;

const offerContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;

  ${mqFrom.Med} {
    flex-direction: row;
    justify-content: center;
  }
`;

export const link = css`
  width: 260px;
  ${calculatorStyles.button};
  margin: 0;
  margin-bottom: 12px;

  ${mqFrom.Med} {
    margin-bottom: 0;
    margin-right: 12px;
  }
`;

export const specialOfferStyles = {
  root,
  title,
  container,
  content,
  offerContainer,
  link,
};
