import { css } from '@emotion/core';
import {
  colors,
  fontFamily,
  fontWeights,
  mqFrom,
  typescale,
  unit,
} from 'styles';

const root = css`
  transition: all 0.5s;
  padding: ${unit * 22}px 12px;

  ${mqFrom.Med} {
    padding: ${unit * 44}px 60px ${unit * 88}px;
  }
`;

const title = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};
  ${typescale.xxxlarge};

  color: ${colors.bg};
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);

  max-width: 60%;
`;

const description = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};
  ${typescale.medium};

  color: ${colors.bg};
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
  opacity: 0.8;

  padding: ${unit * 5}px 0;
  max-width: 70%;
`;

const dot = css`
  background-color: ${colors.dark};
  opacity: 0.2;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  padding: 0;
  border: 0;
  margin: 0 ${unit}px;

  &:disabled {
    background-color: ${colors.primary};
    opacity: 1;
  }

  &:active {
    background-color: ${colors.primary};
    opacity: 1;
  }
`;

export const heroContentStyles = { root, title, description, dot };
