import { BasketIcon, CalculatorIcon } from 'assets';
import { Container } from 'components';
import { Link } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { calculatorStyles } from 'modules/home';
import React from 'react';

export const CalculatorSection: React.FC = () => (
  <section css={calculatorStyles.root}>
    <Container>
      <div css={calculatorStyles.container}>
        <h3 css={calculatorStyles.title}>
          <FormattedMessage id="home.calculator-section.title" />
        </h3>

        <div css={calculatorStyles.content}>
          <div>
            <div css={calculatorStyles.descriptionWrapper}>
              <div css={calculatorStyles.iconWrapper}>
                <CalculatorIcon style={{ width: '50px', height: '50px' }} />
              </div>
              <p css={calculatorStyles.description}>
                <FormattedMessage id="home.calculator-section.subtitle" />
              </p>
            </div>
            <Link
              type="button"
              css={calculatorStyles.button}
              to={'/kalkulatori'}
            >
              <FormattedMessage id="home.calculator-section.try" />
            </Link>
          </div>
          <div css={calculatorStyles.descriptionWrapper}>
            <div css={calculatorStyles.iconWrapper}>
              <BasketIcon
                style={{
                  height: '50px',
                  width: '50px',
                }}
              />
            </div>
            <p css={calculatorStyles.description}>
              <FormattedMessage id="home.calculator-section.add-to-cart" />
            </p>
          </div>
        </div>
      </div>
    </Container>
  </section>
);
