import 'pure-react-carousel/dist/react-carousel.es.css';

import { Container } from 'components';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { reviewStyles } from 'modules/home';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Dot,
  Slide,
  Slider,
} from 'pure-react-carousel';
import React, { useState } from 'react';

export const ReviewSection: React.FC = () => {
  const [autoPlay, setAutoPlay] = useState<boolean>(true);

  const reviewer = graphql`
    query {
      milovanovic: file(
        relativePath: { eq: "reviewers/bojan-milovanovic.jpg" }
      ) {
        id
        childImageSharp {
          fluid(quality: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      njegovac: file(relativePath: { eq: "reviewers/davor-njegovac.jpg" }) {
        id
        childImageSharp {
          fluid(quality: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      burcar_dunovic: file(
        relativePath: { eq: "reviewers/ivana-burcar-dunovic.jpg" }
      ) {
        id
        childImageSharp {
          fluid(quality: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      gezi: file(relativePath: { eq: "reviewers/marijan-gezi.jpg" }) {
        id
        childImageSharp {
          fluid(quality: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      curkovic: file(relativePath: { eq: "reviewers/tomislav-curkovic.jpg" }) {
        id
        childImageSharp {
          fluid(quality: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      trninic: file(relativePath: { eq: "reviewers/zvonimir-trninic.jpg" }) {
        id
        childImageSharp {
          fluid(quality: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `;

  const onMouseEnter = () => {
    if (autoPlay) setAutoPlay(false);
  };

  const onMouseLeave = () => {
    if (!autoPlay) setAutoPlay(true);
  };

  return (
    <div css={reviewStyles.root}>
      <Container className={reviewStyles.container}>
        <h4 css={reviewStyles.title}>
          <FormattedMessage id="home.review-section.title" />
        </h4>
        <CarouselProvider
          visibleSlides={1}
          naturalSlideWidth={100}
          naturalSlideHeight={45}
          totalSlides={2}
          isPlaying={autoPlay}
          interval={5000}
          isIntrinsicHeight
        >
          <div css={reviewStyles.navigationButtonWrapper}>
            <ButtonBack css={reviewStyles.navigationButton}>{'<'}</ButtonBack>
            <ButtonNext css={reviewStyles.navigationButton}>{'>'}</ButtonNext>
          </div>
          <Slider
            trayProps={{
              onMouseEnter: onMouseEnter,
              onMouseLeave: onMouseLeave,
            }}
          >
            <Slide index={0}>
              <div css={reviewStyles.reviewWrapper}>
                <div css={reviewStyles.review}>
                  <p css={reviewStyles.text}>
                    <FormattedMessage id="home.review-section.text.1" />
                  </p>
                  <div css={reviewStyles.authorWrapper}>
                    <GatsbyImage
                      {...useStaticQuery(reviewer).curkovic.childImageSharp}
                      css={reviewStyles.photo}
                    />
                    <div css={reviewStyles.nameWrapper}>
                      <p css={reviewStyles.name}>Tomislav Ćurković,</p>
                      <p css={reviewStyles.role}>
                        <FormattedMessage id="home.review-section.role.1" />
                      </p>
                    </div>
                  </div>
                </div>
                <div css={reviewStyles.review}>
                  <p css={reviewStyles.text}>
                    <FormattedMessage id="home.review-section.text.2" />
                  </p>
                  <div css={reviewStyles.authorWrapper}>
                    <GatsbyImage
                      {...useStaticQuery(reviewer).milovanovic.childImageSharp}
                      css={reviewStyles.photo}
                    />
                    <div css={reviewStyles.nameWrapper}>
                      <p css={reviewStyles.name}>
                        doc. dr.sc Bojan Milovanović,
                      </p>
                      <p css={reviewStyles.role}>
                        <FormattedMessage id="home.review-section.role.2" />
                      </p>
                    </div>
                  </div>
                </div>
                <div css={reviewStyles.review}>
                  <p css={reviewStyles.text}>
                    <FormattedMessage id="home.review-section.text.3" />
                  </p>
                  <div css={reviewStyles.authorWrapper}>
                    <GatsbyImage
                      {...useStaticQuery(reviewer).burcar_dunovic
                        .childImageSharp}
                      css={reviewStyles.photo}
                    />
                    <div css={reviewStyles.nameWrapper}>
                      <p css={reviewStyles.name}>
                        izv. prof. dr. sc. Ivana Burcar Dunović,
                      </p>
                      <p css={reviewStyles.role}>
                        <FormattedMessage id="home.review-section.role.3" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <Slide index={1}>
              <div css={reviewStyles.reviewWrapper}>
                <div css={reviewStyles.review}>
                  <p css={reviewStyles.text}>
                    <FormattedMessage id="home.review-section.text.4" />
                  </p>
                  <div css={reviewStyles.authorWrapper}>
                    <GatsbyImage
                      {...useStaticQuery(reviewer).trninic.childImageSharp}
                      css={reviewStyles.photo}
                    />
                    <div css={reviewStyles.nameWrapper}>
                      <p css={reviewStyles.name}>Zvonimir Trninić,</p>
                      <p css={reviewStyles.role}>
                        <FormattedMessage id="home.review-section.role.4" />
                      </p>
                    </div>
                  </div>
                </div>
                <div css={reviewStyles.review}>
                  <p css={reviewStyles.text}>
                    <FormattedMessage id="home.review-section.text.5" />
                  </p>
                  <div css={reviewStyles.authorWrapper}>
                    <GatsbyImage
                      {...useStaticQuery(reviewer).gezi.childImageSharp}
                      css={reviewStyles.photo}
                    />
                    <div css={reviewStyles.nameWrapper}>
                      <p css={reviewStyles.name}>Marijan Gezi,</p>
                      <p css={reviewStyles.role}>
                        <FormattedMessage id="home.review-section.role.5" />
                      </p>
                    </div>
                  </div>
                </div>
                <div css={reviewStyles.review}>
                  <p css={reviewStyles.text}>
                    <FormattedMessage id="home.review-section.text.6" />
                  </p>
                  <div css={reviewStyles.authorWrapper}>
                    <GatsbyImage
                      {...useStaticQuery(reviewer).njegovac.childImageSharp}
                      css={reviewStyles.photo}
                    />
                    <div css={reviewStyles.nameWrapper}>
                      <p css={reviewStyles.name}>Davor Njegovac,</p>
                      <p css={reviewStyles.role}>
                        <FormattedMessage id="home.review-section.role.6" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          </Slider>
          <div css={reviewStyles.dotWrapper}>
            <Dot slide={0} css={reviewStyles.dot}></Dot>
            <Dot slide={1} css={reviewStyles.dot}></Dot>
          </div>
        </CarouselProvider>
      </Container>
    </div>
  );
};
