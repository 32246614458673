import { css } from '@emotion/core';
import {
  colors,
  fontFamily,
  fontWeights,
  layout,
  mqFrom,
  typescale,
  unit,
} from 'styles';

const root = css`
  padding-bottom: ${unit * 10}px;
`;

const container = css`
  ${layout.wrapper}
  position: relative;
`;

const infoWrapper = css`
  position: relative;
  background: ${colors.bg};
  padding: ${unit * 3}px ${unit * 3}px;
  bottom: -${unit * 10}px;

  transition: all 0.5s;

  ${mqFrom.Med} {
    position: absolute;
    padding: ${unit * 10}px ${unit * 10}px;
    margin-right: ${unit * 20}px;
    margin-left: ${unit * 5}px;
  }

  ${mqFrom.Lrg} {
    position: absolute;
    margin-right: ${unit * 30}px;
    margin-left: ${unit * 15}px;
  }
`;

const infoSection = css`
  display: flex;
  padding-top: ${unit * 4}px;
  flex-direction: column;

  transition: all 0.5s;

  ${mqFrom.Med} {
    padding-top: ${unit * 8}px;
    flex-direction: row;
  }

  ${mqFrom.Sml} {
    flex-direction: row;
  }
`;

const infoTitle = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightBold};
  ${typescale.large};

  color: ${colors.dark};
`;

const step = css`
  text-transform: uppercase;

  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};
  ${typescale.tiny};

  color: ${colors.primary};
  white-space: nowrap;
  padding: 0 ${unit * 2}px;
  padding-bottom: ${unit * 3}px;
`;

const subtitle = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightBold};
  ${typescale.medium};

  color: ${colors.dark};

  padding-bottom: ${unit * 4}px;
`;

const text = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};
  ${typescale.small};

  color: ${colors.textSecondary};
`;

const content = css`
  padding-top: ${unit * 3}px;

  ${mqFrom.Sml} {
    padding-top: 0;
    padding-right: ${unit * 5}px;
  }
`;

const titleWrapper = css`
  display: flex;
  flex-direction: row;
`;

const dot = css`
  border: none;
  border-top: 2px dotted ${colors.dark};
  margin-top: 6px;
  color: ${colors.dark};
  height: 1px;
  width: 100%;

  opacity: 0.2;
`;

const dotWrapper = css`
  position: absolute;

  top: -120px;

  ${mqFrom.Med} {
    top: -50px;
    left: 0;
  }
`;

const navigationDot = css`
  background-color: ${colors.backgroundGray};
  opacity: 0.4;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  padding: 0;
  border: 0;
  margin: 0 ${unit}px;

  &:disabled {
    background-color: ${colors.bg};
    opacity: 1;
  }

  &:active {
    background-color: ${colors.bg};
    opacity: 1;
  }
`;

const image = css`
  height: 100%;
`;

export const heroStyles = {
  root,
  container,
  content,
  infoWrapper,
  infoSection,
  infoTitle,
  titleWrapper,
  navigationDot,
  dotWrapper,
  step,
  subtitle,
  dot,
  text,
  image,
};
