import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { HomeArticle } from 'modules/home';
import React from 'react';

import { ProductSection } from './ProductSection';

export const TercaFacadeBrick: React.FC = () => {
  const facadePhoto = graphql`
    query {
      brick: file(relativePath: { eq: "homepage-facade.png" }) {
        id
        childImageSharp {
          fluid(quality: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      facade_1: allCombinedProductsJson(
        filter: {
          commercial: {
            navigationCategories: { code: { eq: "Facade" } }
            name: { eq: "Forum ombra slip opeka" }
          }
        }
      ) {
        nodes {
          commercial {
            assets {
              otherImgs {
                imgUrl
                assetType
              }
              mainImg
            }
            model
            category
            name
            localizedName
            navigationCategories {
              code
            }
          }
          id
        }
      }
      facade_2: allCombinedProductsJson(
        filter: {
          commercial: {
            navigationCategories: { code: { eq: "Facade" } }
            name: { eq: "Metropolis urbis bruin slip opeka" }
          }
        }
      ) {
        nodes {
          commercial {
            assets {
              otherImgs {
                imgUrl
                assetType
              }
              mainImg
            }
            model
            category
            name
            localizedName
            navigationCategories {
              code
            }
          }
          id
        }
      }
      facade_3: allCombinedProductsJson(
        filter: {
          commercial: {
            navigationCategories: { code: { eq: "Facade" } }
            name: { eq: "Abote slip opeka" }
          }
        }
      ) {
        nodes {
          commercial {
            assets {
              otherImgs {
                imgUrl
                assetType
              }
              mainImg
            }
            model
            category
            name
            localizedName
            navigationCategories {
              code
            }
          }
          id
        }
      }
    }
  `;

  const { formatMessage } = useIntl();
  const facade1 = useStaticQuery(facadePhoto).facade_1.nodes[0];
  const facade2 = useStaticQuery(facadePhoto).facade_2.nodes[0];
  const facade3 = useStaticQuery(facadePhoto).facade_3.nodes[0];

  const products: HomeArticle[] = [
    {
      image:
        facade1.commercial.assets.mainImg ||
        facade1.commercial.assets.otherImgs[0].imgUrl,
      title: facade1.commercial.localizedName,
      type: facade1.commercial.category,
      imageAlt: facade1.commercial.localizedName,
      id: facade1.id,
      commercial: facade1.commercial,
    },
    {
      image:
        facade2.commercial.assets.mainImg ||
        facade2.commercial.assets.otherImgs[0].imgUrl,
      title: facade2.commercial.localizedName,
      type: facade2.commercial.category,
      imageAlt: facade2.commercial.localizedName,
      id: facade2.id,
      commercial: facade2.commercial,
    },
    {
      image:
        facade3.commercial.assets.mainImg ||
        facade3.commercial.assets.otherImgs[0].imgUrl,
      title: facade3.commercial.localizedName,
      type: facade3.commercial.category,
      imageAlt: facade3.commercial.localizedName,
      id: facade3.id,
      commercial: facade3.commercial,
    },
  ];

  return (
    <ProductSection
      title={formatMessage({ id: 'home.terca-facade-brick.title' })}
      description={formatMessage({ id: 'home.terca-facade-brick.description' })}
      image={useStaticQuery(facadePhoto).brick}
      imageAlt={formatMessage({ id: 'home.terca-facade-brick.imageAlt' })}
      imagePosition="right"
      products={products}
      allProducts="/fasada/pregled-svih-proizvoda"
    />
  );
};
