import { Container } from 'components';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import GatsbyImage from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { warrantyStyles } from 'modules/home';
import React from 'react';
import { buttons } from 'styles';

export const WarrantySection: React.FC = () => {
  const warrantyPhoto = graphql`
    query {
      warranty: file(relativePath: { eq: "homepage-warranty.png" }) {
        id
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      warrantyBackground: file(
        relativePath: { eq: "warranty-background.png" }
      ) {
        id
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `;

  return (
    <section css={warrantyStyles.root}>
      <Container>
        <div css={warrantyStyles.container}>
          <div css={warrantyStyles.imagesWrapper}>
            <BackgroundImage
              {...useStaticQuery(warrantyPhoto).warrantyBackground
                .childImageSharp}
              css={warrantyStyles.backgroundImage}
            >
              <GatsbyImage
                {...useStaticQuery(warrantyPhoto).warranty.childImageSharp}
                alt={'33 godine jamstva'}
                css={warrantyStyles.logo}
              />
            </BackgroundImage>
          </div>
          <div css={warrantyStyles.titleWrapper}>
            <p css={warrantyStyles.title}>
              <FormattedMessage id="home.warranty-section.title" />
            </p>
            <a
              href="https://www.wienerberger.hr/informacije/tondach-jamstvo.html"
              target="_blank"
              rel="noopener noreferrer"
              css={buttons.ghost.medium}
            >
              <FormattedMessage id="home.warranty-section.learn-more" />
            </a>
          </div>
        </div>
      </Container>
    </section>
  );
};
