import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { HomeArticle, productStyles } from 'modules/home';
import React from 'react';
import { getProductRoute } from 'utils';

interface Props {
  title: string;
  description: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  image: any;
  imageAlt: string;
  imagePosition: 'left' | 'right';
  products: HomeArticle[];
  allProducts: string;
}

export const ProductSection: React.FC<Props> = ({
  title,
  description,
  image,
  imageAlt,
  products,
  imagePosition = 'left',
  allProducts,
}) => (
  <section css={productStyles.root}>
    {imagePosition === 'left' && (
      <GatsbyImage
        {...image.childImageSharp}
        alt={imageAlt}
        css={productStyles.photoLeft}
      />
    )}
    <div css={productStyles.wrapper}>
      <div>
        <div css={productStyles.titleWrapper}>
          <p css={productStyles.title}>{title}</p>
          <Link to={allProducts} css={productStyles.button}>
            <FormattedMessage id="home.product-section.all-products" />
          </Link>
        </div>
        <p css={productStyles.description}>{description}</p>
      </div>
      <div css={productStyles.productWrapper}>
        {products.map((product: HomeArticle) => (
          <div key={product.id} css={productStyles.product}>
            <Link to={getProductRoute(product.commercial)}>
              <GatsbyImage
                fluid={{
                  src: product.image,
                  aspectRatio: 1,
                  srcSet: '',
                  sizes: '',
                }}
                alt={product.imageAlt || product.title}
                css={productStyles.productPhoto}
                imgStyle={{
                  objectFit: 'contain',
                }}
              />
              <p css={productStyles.productType}>{product.type}</p>
              <p css={productStyles.productTitle}>{product.title}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
    {imagePosition === 'right' && (
      <GatsbyImage
        {...image.childImageSharp}
        alt={imageAlt}
        css={productStyles.photoRight}
      />
    )}
  </section>
);
